// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:f5c5ee49-0ccf-42c1-91d4-3721d18d57ae",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_qpJStLedX",
    "aws_user_pools_web_client_id": "400fge16ur37u2n2nm0nt3m47j",
    "oauth": {},
    "aws_content_delivery_bucket": "sumerian-amplify-app-20190501143826-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d1tgnf15xnzsma.cloudfront.net"
};


export default awsmobile;
