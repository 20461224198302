import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import aws_exports from './aws-exports';
import Amplify, { XR } from 'aws-amplify';
import scene_config from './sumerian_exports';
import { withAuthenticator, SumerianScene } from 'aws-amplify-react';
import AWS from 'aws-sdk';

new AWS.Polly()

XR.configure({ // XR category configuration
  SumerianProvider: { // Sumerian-specific configuration
    region: 'eu-west-1', // Sumerian scene region
    scenes: {
      "SumerianAmplify": {  // Friendly scene name
         sceneConfig: scene_config // Scene JSON configuration
       },
    }
  }
});

Amplify.configure(aws_exports);

/*
if (XR.isSceneLoaded('SumerianAmplify')) {

    if (XR.isMuted('SumerianAmplify')) {
        // The scene is muted
        XR.setMuted('SumerianAmplify', false) // Unmute
    }

}
*/

class App extends Component {
  render() {
    // const Style = {width : '1920px', height : '1080px'};
    // <div style={Style}>
    return (
      <div>
        <SumerianScene sceneName='SumerianAmplify' />
      </div>
    );
  }
}

export default withAuthenticator(App, { includeGreetings: true });


